/*
 * Route helpers for graph
 *
 * These live outside of the individual page files to allow lazy loading / code
 * splitting.
 */

import _ from 'lodash';
import LZString from 'lz-string';
import * as myzod from 'myzod';
import pathToRegExp from 'path-to-regexp';
import { ParsedQuery, stringify } from 'query-string';

import { assertUnreachableOrReturnDefault } from 'src/lib/assertUnreachable';
import Config from 'src/lib/config';
import { TimeRangeOption } from 'src/lib/config/timeRangeOptions';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import {
  NewNormalizedMetricsFilterField,
  NormalizedFieldsMetricsField,
  NormalizedFieldsMetricsFilterField,
  NormalizedMetricsField,
  NormalizedMetricsFilterField,
  metricConfig,
} from 'src/lib/metrics';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

import { ProposeSchemaChangesModalStateFragment } from '../shared/routes';

const TimeRangeRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        range: myzod
          .literals(
            ...([
              'lastFiveMinutes',
              'lastHour',
              'lastFourHours',
              'lastDay',
              'lastThreeDays',
              'lastWeek',
              'lastMonth',
              'lastThreeMonths',
              'custom',
            ] as TimeRangeOption[]),
          )
          .optional(),
        to: myzod.string().optional(),
        from: myzod.string().optional(),
        fastModeRange: myzod
          .literals('lastMonth', 'lastThreeMonths')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const legacyServiceRouteConfig = new RouteConfig({
  owners: ['Unowned'],
  definition: '/service/:graphId',
  parseMatchParams: (params) =>
    myzod.object({ graphId: myzod.string() }).allowUnknownKeys().parse(params),
});
/**
 * LEGACY RouteConfig for the top-level `/graphs`
 * The same as `graphRouteConfig` as of March 2023, but with `variant` queryParam
 */
export const legacyGraphRouteConfig = new RouteConfig({
  owners: ['Unowned'],
  definition: '/:graphVisibilityType(graph|public)/:graphId',
  parseMatchParams: (params) =>
    myzod
      .object({
        graphVisibilityType: myzod
          .literals('graph', 'public')
          .default('graph')
          .optional(),
        graphId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  locationPathname: (params) => {
    return compiledGraphRouteConfigDefinition({
      ...params,
      graphVisibilityType: params.graphVisibilityType ?? 'graph',
    });
  },
  parseSearchParams: (params) =>
    myzod
      .object({
        variant: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

/**
 * RouteConfig for the top-level `/graphs`
 * If this changes, please also update paths in packages/studio-ui/functions/handleSiteMap.ts
 */
export const graphRouteConfig = new RouteConfig({
  owners: ['Unowned'],
  definition: '/:graphVisibilityType(graph|public)/:graphId',
  // XXX(Chang): this parseSearchParams is being called a ridiculous number of times on each variant page (e.g. schema documentation), with each usage of useInternalGraphLinking
  // memoizing the function as a hack to avoid performance slowdowns
  // https://apollographql.atlassian.net/browse/NEBULA-3181
  parseMatchParams: _.memoize(
    (params) => {
      return myzod
        .object({
          graphVisibilityType: myzod
            .literals('graph', 'public')
            .default('graph')
            .optional(),
          graphId: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params);
    },
    (...args) => {
      return JSON.stringify(args);
    },
  ),
  locationPathname: (params) => {
    return compiledGraphRouteConfigDefinition({
      ...params,
      graphVisibilityType: params.graphVisibilityType ?? 'graph',
    });
  },
});

const compiledGraphRouteConfigDefinition = pathToRegExp.compile(
  graphRouteConfig.definition,
);

export const variantRouteConfig = graphRouteConfig.extend(
  new RouteConfig({
    owners: ['Unowned'],
    definition: '/variant/:graphVariant',
    parseMatchParams: (params) => {
      const data = myzod
        .object({ graphVariant: myzod.string() })
        .allowUnknownKeys()
        .parse(params);
      return { ...data, graphVariant: decodeURIComponent(data.graphVariant) };
    },
    locationPathname(params) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        graphVariant: encodeURIComponent(params.graphVariant),
      });
    },
  }),
);
export const proposalRouteConfig = graphRouteConfig.extend(
  new RouteConfig({
    owners: ['proposals'],
    definition: '/proposal/:graphVariant',
    parseMatchParams: (params) => {
      const data = myzod
        .object({ graphVariant: myzod.string() })
        .allowUnknownKeys()
        .parse(params);
      return { ...data, graphVariant: decodeURIComponent(data.graphVariant) };
    },
    locationPathname(params) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        graphVariant: encodeURIComponent(params.graphVariant),
      });
    },
    parseSearchParams: (params) => {
      return myzod
        .object({
          pullUpstreamChanges: myzod.literals('true', 'false').optional(),
        })
        .allowUnknownKeys()
        .parse(params);
    },
  }),
);

const DeleteProposalCommentModalRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        commentThreadId: myzod.string().optional(),
        proposalCommentId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

type ProposalEditorHashParams = {
  linkedCoordinateName?: string;
};

export const proposalEditorRouteConfig = proposalRouteConfig
  .extend(DeleteProposalCommentModalRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['proposals'],
      definition: '/editor',
      parseSearchParams: (params) =>
        myzod
          .object({
            selectedSchema: myzod.string().optional(),
            newSubgraphName: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseHash: (hash) => {
        return hash.length ? { linkedCoordinateName: hash } : {};
      },
      locationHash: ({ linkedCoordinateName }: ProposalEditorHashParams) =>
        linkedCoordinateName ?? '',
    }),
  );

type ProposalChangesHashParams = {
  // this is the group name for each group of detailed changes in the # of the changes route
  linkedGroupName?: string;
  // this is the coordinate name that allows us to link to specific coordinates on subgraphs
  linkedCoordinateName?: string;
};

export const proposalChangesRouteConfig = proposalRouteConfig
  .extend(DeleteProposalCommentModalRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['proposals'],
      definition: '/changes',
      parseSearchParams: (params) =>
        myzod
          .object({
            selectedSchema: myzod.string().optional(),
            includeImplementedChanges: myzod.string().optional(),
            selectedRevision: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseHash: (hash) => {
        const [linkedGroupName, ...rest] = hash.split('.');
        return linkedGroupName.length
          ? rest.length
            ? { linkedGroupName, linkedCoordinateName: rest.join('.') }
            : { linkedGroupName }
          : {};
      },
      locationHash: ({
        linkedGroupName,
        linkedCoordinateName,
      }: ProposalChangesHashParams) =>
        linkedGroupName
          ? linkedCoordinateName
            ? `${linkedGroupName}.${linkedCoordinateName}`
            : linkedGroupName
          : '',
    }),
  );

const ProposalListRouteFragment = new RouteConfig({
  owners: ['proposals'],
  definition: '/proposals',
  parseSearchParams: (params) =>
    myzod
      .object({
        page: myzod.number().coerce().optional(),
        filterByStatuses: myzod
          .array(myzod.enum(GraphQLTypes.ProposalStatus))
          .coerce((value) =>
            Object.values<string>(GraphQLTypes.ProposalStatus).includes(value)
              ? [value as GraphQLTypes.ProposalStatus]
              : [],
          )
          .optional(),
        filterBySubgraphs: myzod
          .array(myzod.string())
          .coerce((value) => [value])
          .optional(),
        filterByVariants: myzod
          .array(myzod.string())
          .coerce((value) => [value])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const graphProposalListRouteConfig = graphRouteConfig
  .extend(ProposalListRouteFragment)
  .extend(ProposeSchemaChangesModalStateFragment);

export const variantProposalListRouteConfig = variantRouteConfig
  .extend(ProposalListRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: '/:view(graph|variant)',
      parseMatchParams: (params) =>
        myzod
          .object({
            view: myzod.literals('graph', 'variant'),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(ProposeSchemaChangesModalStateFragment);

export const persistedQueriesRouteConfig = graphRouteConfig.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/persisted-queries' }),
);

export const persistedQueryListRouteConfig = persistedQueriesRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/list/:listId',
    parseMatchParams: (params) =>
      myzod.object({ listId: myzod.string() }).allowUnknownKeys().parse(params),
  }),
);

export const variantHomepageRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/home',
  }),
);
export const proposalHomepageRouteConfig = proposalRouteConfig
  .extend(
    new RouteConfig({
      owners: ['proposals'],
      definition: '/home',
      parseSearchParams: (params) =>
        myzod
          .object({
            // These params are distinct from the similar params below to prevent
            // closing of the thread after the user uses the Delete Comment modal
            focusCommentId: myzod.string().optional(),
            focusThreadId: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(DeleteProposalCommentModalRouteFragment);

export const launchesRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['launches'],
    definition: '/launches/:launchId?',
    parseMatchParams: (params) =>
      myzod
        .object({
          launchId: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseSearchParams: (params) =>
      myzod
        .object({
          [Config.queryParameters.ComparisonId]: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const legacyIntegrationsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['Unowned'], // TODO: needs owner, this will be the same as the reporting settings tab
    definition: '/integrations',
  }),
);

export const legacyImplementingServicesRouteConfig = variantRouteConfig
  .extend(TimeRangeRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: `/service-list`,
      parseSearchParams: (params) => {
        const { currentService, ...rest } = myzod
          .object({
            currentService: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params);
        return {
          ...rest,
          ...(currentService ? { selectedSchema: currentService } : {}),
        };
      },
      locationSearch(params) {
        const { selectedSchema, ...sanitizedParams } =
          this.parseSearchParams(params);
        return stringify({
          ...sanitizedParams,
          currentService: params.selectedSchema,
        });
      },
    }),
  );

/**
 * Routing configuration for Clients L1
 */
export const clientsRouteConfig = variantRouteConfig
  .extend(TimeRangeRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['insights'],
      definition: '/clients',
      parseSearchParams: (params) =>
        myzod
          .object({
            clientName: myzod.string().nullable().optional(),
            clientVersion: myzod.string().nullable().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const atlasExplorerRouteConfig = new RouteConfig({
  owners: ['Unowned'], // TODO: needs owner
  definition: '/directory',
  parseSearchParams: (params) =>
    myzod
      .object({
        graphRef: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const createExplorerUrlState = ({
  document,
  variables,
  headers,
  preflightOperationScript,
  postflightOperationScript,
  savedCollectionId,
  savedCollectionEntryId,
  sharedCollectionOriginVariantRef,
  includeCookies,
}: Partial<{
  document: string | undefined;
  variables: string | undefined;
  headers: string | undefined;
  preflightOperationScript: string | undefined;
  postflightOperationScript: string | undefined;
  savedCollectionId: string | undefined;
  savedCollectionEntryId: string | undefined;
  sharedCollectionOriginVariantRef: string | undefined;
  includeCookies: string | undefined;
}>) =>
  typeof document === 'string' ||
  typeof variables === 'string' ||
  typeof headers === 'string' ||
  typeof preflightOperationScript === 'string' ||
  typeof postflightOperationScript === 'string' ||
  typeof savedCollectionId === 'string' ||
  typeof savedCollectionEntryId === 'string' ||
  typeof includeCookies === 'string'
    ? LZString.compressToEncodedURIComponent(
        JSON.stringify({
          document,
          variables,
          headers,
          preflightOperationScript,
          postflightOperationScript,
          savedCollectionId,
          savedCollectionEntryId,
          sharedCollectionOriginVariantRef,
          includeCookies,
        }),
      )
    : undefined;

const ExplorerIntialValueRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (queryStringParams: unknown) => {
    const explorerURLState =
      typeof queryStringParams === 'object' &&
      queryStringParams &&
      hasOwnProperty(queryStringParams, 'explorerURLState') &&
      typeof queryStringParams.explorerURLState === 'string'
        ? JSON.parse(
            LZString.decompressFromEncodedURIComponent(
              queryStringParams.explorerURLState,
            ) || '{}',
          )
        : null;

    return myzod
      .object({
        document: myzod.string().optional(),
        searchQuery: myzod.string().optional(),
        variables: myzod.string().optional(),
        headers: myzod.string().optional(),
        preflightOperationScript: myzod.string().optional(),
        postflightOperationScript: myzod.string().optional(),
        [Config.queryParameters.Referrer]: myzod.string().optional(),
        savedCollectionId: myzod.string().optional(),
        savedCollectionEntryId: myzod.string().optional(),
        sharedCollectionOriginVariantRef: myzod.string().optional(),
        includeCookies: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse({
        ...(typeof queryStringParams === 'object' && queryStringParams),
        ...(typeof explorerURLState === 'object' && explorerURLState),
      });
  },
  locationSearch({
    document,
    variables,
    headers,
    preflightOperationScript,
    postflightOperationScript,
    savedCollectionId,
    savedCollectionEntryId,
    sharedCollectionOriginVariantRef,
    includeCookies,
    ...params
  }) {
    return stringify({
      explorerURLState: createExplorerUrlState({
        document,
        variables,
        headers,
        preflightOperationScript,
        postflightOperationScript,
        savedCollectionId,
        savedCollectionEntryId,
        sharedCollectionOriginVariantRef,
        includeCookies,
      }),
      ...this.parseSearchParams(params as ParsedQuery<string>),
    });
  },
});

const EmbedExplorerModalRouteFragment = new RouteConfig({
  definition: '',
  parseState: (params) =>
    myzod
      .object({
        embedModalTabState: myzod
          .object({
            document: myzod.string(),
            variables: myzod.string(),
            headers: myzod.string(),
            includeCookies: myzod.literals('true', 'false').optional(),
          })
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const SupergraphEndpointSuccessNudgeRouteFragment = new RouteConfig({
  definition: '',
  parseState: (params) =>
    myzod
      .object({
        showSupergraphEndpointSuccessNudge: myzod
          .literals('true', 'false')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const OperationCollectionsRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        collectionId: myzod.string().optional(),
        focusCollectionId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const baseExplorerRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: '/explorer',
})
  .extend(ExplorerIntialValueRouteFragment)
  .extend(EmbedExplorerModalRouteFragment)
  .extend(OperationCollectionsRouteFragment)
  .extend(SupergraphEndpointSuccessNudgeRouteFragment);
export const explorerRouteConfig = variantRouteConfig.extend(
  baseExplorerRouteConfig,
);

export const insightsFilterRouteFragment = TimeRangeRouteFragment.extend(
  new RouteConfig({
    definition: '',
    parseSearchParams: (params) =>
      myzod
        .object({
          [Config.queryParameters.SelectedQueryId]: myzod.string().optional(),
          [Config.queryParameters.SelectedQueryName]: myzod
            .string()
            .nullable()
            .optional(),
          [Config.queryParameters.QueryListMetricSort]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allOperationsMetrics,
              ) as NormalizedMetricsField[]),
            )
            .optional(),
          [Config.queryParameters.QueryListMetricFilter]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allOperationsFilters,
              ) as NormalizedMetricsFilterField[]),
            )
            .optional(),
          [Config.queryParameters.OperationListSearchFilter]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListSortDirection]: myzod
            .literals('asc', 'desc')
            .optional(),
          [Config.queryParameters.OperationListMetricSort]: myzod
            .literals(...Object.keys(metricConfig.allOperationsMetrics))
            .optional(),
          [Config.queryParameters.OperationListMetricFilter]: myzod
            .literals(...Object.keys(metricConfig.allOperationsFilters))
            .optional(),
          [Config.queryParameters.OperationListMetricFilters]: myzod
            .array(
              myzod.literals(
                ...(Object.keys(
                  metricConfig.newAllOperationsFilters,
                ) as NewNormalizedMetricsFilterField[]),
              ),
            )
            .coerce((value) => [value as NewNormalizedMetricsFilterField])
            .optional(),
          [Config.queryParameters.OperationListBeforeCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListAfterCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.OperationListPage]: myzod
            .number()
            .coerce()
            .optional(),
          [Config.queryParameters.OperationListPaginationDirection]: myzod
            .literals('first', 'last')
            .optional(),
          [Config.queryParameters.FieldListSearchFilter]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListMetricSort]: myzod
            .literals(
              ...(Object.keys(
                metricConfig.allFieldsMetrics,
              ) as NormalizedFieldsMetricsField[]),
            )
            .optional(),
          [Config.queryParameters.FieldListMetricFilter]: myzod
            .union([
              myzod.literal('none'), // unfiltered / show everything
              myzod
                .array(
                  myzod.literals(
                    ...(Object.keys(
                      metricConfig.allFieldsFilters,
                    ) as NormalizedFieldsMetricsFilterField[]),
                  ),
                )
                .coerce(
                  (value) => [value] as NormalizedFieldsMetricsFilterField[],
                ),
            ])
            .optional(),
          [Config.queryParameters.FieldListSortDirection]: myzod
            .literals('asc', 'desc')
            .optional(),
          [Config.queryParameters.FieldListBeforeCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListAfterCursor]: myzod
            .string()
            .optional(),
          [Config.queryParameters.FieldListPage]: myzod
            .number()
            .coerce()
            .optional(),
          [Config.queryParameters.FieldListPaginationDirection]: myzod
            .literals('first', 'last')
            .optional(),
          [Config.queryParameters.FastModeRange]: myzod
            .literals('lastMonth', 'lastThreeMonths')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          clients: myzod
            .union([
              myzod.record(
                myzod.union([myzod.array(myzod.string()), myzod.literals('*')]),
              ),
              myzod.null(),
            ])
            .optional(),
          previousFieldName: myzod.string().optional(),
          previousFieldType: myzod.string().optional(),
          previousInputFieldNamedAttribute: myzod.string().optional(),
          previousInputFieldNamedType: myzod.string().optional(),
          previousEnumNamedAttribute: myzod.string().optional(),
          previousEnumNamedType: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 *  These are shared between insightsRouteConfig and fieldRouteConfigs
 *  so that we can preserve them across navigations, but we should consider
 *  just merging these 2 route configs now by moving typeName and fieldName
 *  to search params.
 */
const sharedInsightsSearchParams = {
  [Config.queryParameters.ActiveReportPaneTab]: myzod
    .literals('usage', 'errors', 'traces', 'operation', 'performance')
    .optional(),
  [Config.queryParameters.SelectedTraceId]: myzod.string().optional(),
  [Config.queryParameters.SelectedDurationBucket]: myzod
    .string()
    .nullable()
    .optional(),
  [Config.queryParameters.InsightsTab]: myzod
    .literals(
      'fields',
      'operations',
      'inputFields',
      'enumValues',
      'objectFields',
    )
    .optional(),
  [Config.queryParameters.CoordinateKind]: myzod
    .enum(GraphQLTypes.CoordinateKind)
    .optional(),
};

export const fieldRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['insights'],
      definition: '/insights/schema/:typeName.:fieldName',
      parseMatchParams: (params) =>
        myzod
          .object({
            typeName: myzod.string(),
            fieldName: myzod.string(),
          })
          .allowUnknownKeys()
          .parse(params),
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

export const coordinateNotFoundRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['insights'],
      definition: '/insights/schema/notFound',
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

export const legacyInsightsRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['insights'],
      definition: '/:page(metrics|operations)',
      parseMatchParams: (params) =>
        myzod
          .object({ page: myzod.literals('metrics', 'operations') })
          .allowUnknownKeys()
          .parse(params),
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
            [Config.queryParameters.Overlay]: myzod
              .literals('operation-details')
              .optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );
export const insightsRouteConfig = variantRouteConfig
  .extend(insightsFilterRouteFragment)
  .extend(
    new RouteConfig({
      owners: ['insights'],
      definition: '/insights',
      parseSearchParams: (params) =>
        myzod
          .object({
            ...sharedInsightsSearchParams,
            [Config.queryParameters.Overlay]: myzod
              .literals('operation-details')
              .optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

const referenceCategorySlugSchema = myzod.literals(
  'objects',
  'scalars',
  'interfaces',
  'unions',
  'enums',
  'inputs',
  'directives',
);
export type ReferenceCategorySlug = myzod.Infer<
  typeof referenceCategorySlugSchema
>;

const commonSettings = [
  'general',
  'checks',
  'checks_custom_checks',
  'checks_operations',
  'checks_linter',
  'checks_proposals',
] as const;
const graphSettingsSlugSchema = myzod.literals(
  'permissions',
  'api-keys',
  'reporting',
  'variants',
  'proposals',
  ...commonSettings,
);
export type GraphSettingsSlug = myzod.Infer<typeof graphSettingsSlugSchema>;

const variantSettingsSlugSchema = myzod.literals(
  'explorer',
  'contracts',
  'routing',
  ...commonSettings,
);
export type VariantSettingsSlug = myzod.Infer<typeof variantSettingsSlugSchema>;

/**
 * Routing configuration for Dedicated GraphOS Settings L1 tab and page
 */
const cloudSettingsSlugSchema = myzod.literals(
  'general',
  'endpoint-urls',
  'configuration',
);
export type CloudSettingsSlug = myzod.Infer<typeof cloudSettingsSlugSchema>;

const baseSchemaRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: '/schema',
  parseSearchParams: (params) =>
    myzod
      .object({
        federationIntro: myzod
          .literals(
            'intro',
            'graph-label',
            'subgraph-of-origin',
            'explore-schema',
          )
          .optional(),
        publishUpdate: myzod.literal('true').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});
export const schemaRouteConfig = variantRouteConfig.extend(
  baseSchemaRouteConfig,
);
export const legacySchemaChangelogRouteConfig = schemaRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/changelog',
  }),
);
export const legacySchemaSingleChangelogRouteConfig =
  legacySchemaChangelogRouteConfig.extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: '/version/:version',
      parseMatchParams: (params) =>
        myzod
          .object({ version: myzod.string() })
          .allowUnknownKeys()
          .parse(params),
    }),
  );
export const legacySchemaSchemaTagChangelogRouteConfig =
  legacySchemaChangelogRouteConfig.extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: '/schemaTagId/:version',
      parseMatchParams: (params) =>
        myzod
          .object({ version: myzod.string() })
          .allowUnknownKeys()
          .parse(params),
    }),
  );

export const proposalSchemaRouteConfig = proposalRouteConfig.extend(
  baseSchemaRouteConfig,
);

export type SDLHash = {
  graphqlTypeName?: string;
  fieldName?: string;
  argumentName?: string;
  directiveName?: string;
};

const baseSdlRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: `/sdl`,
}).extend(
  new RouteConfig({
    definition: '',
    parseSearchParams: (params) =>
      myzod
        .object({
          selectedSchema: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseHash: (hash): SDLHash => {
      const parsedHash = hash?.split('.');
      const [firstElement, secondElement, thirdElement] = parsedHash || [];

      if (firstElement?.includes('@')) {
        return {
          directiveName: firstElement.substring(1),
          argumentName: secondElement,
        };
      }
      return {
        graphqlTypeName: firstElement,
        fieldName: secondElement,
        argumentName: thirdElement,
      };
    },
    locationHash: ({
      graphqlTypeName,
      fieldName,
      argumentName,
      directiveName,
    }: SDLHash) =>
      directiveName
        ? `@${directiveName}${argumentName ? `.${argumentName}` : ''}`
        : graphqlTypeName
          ? `${graphqlTypeName}${
              fieldName
                ? argumentName
                  ? `.${fieldName}.${argumentName}`
                  : `.${fieldName}`
                : ''
            }`
          : '',
  }),
);

export function isTagHighlight(
  highlight: Parameters<
    (typeof visualizationRouteConfig)['path']
  >[0]['highlight'],
): highlight is `tag-${string}` {
  return !!highlight?.match(/tag-.*/);
}
export function isDirectiveHighlight(
  highlight: Parameters<
    (typeof visualizationRouteConfig)['path']
  >[0]['highlight'],
): highlight is `directive-${string}` {
  return !!highlight?.match(/directive-.*/);
}
const stableCastArray = _.memoize(_.castArray, (arr: string[] | string) =>
  typeof arr === 'string' ? arr : arr.join(','),
);
const baseVisualizationRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: `/visualization`,
  parseSearchParams: (params) => {
    const highlightLiterals = myzod.literals(
      'Errors',
      'Latency',
      'Usage',
      'Entities',
      'Unused',
    );

    const { subgraphs, tags, highlight, ...parsedParams } = myzod
      .object({
        focused: myzod.string().optional(),
        rootType: myzod.string().optional(),
        subgraphs: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        tags: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        collapseTypes: myzod.literals('true', 'false').optional(),
        hideUnusedFields: myzod.literals('true', 'false').optional(),
        hideDeprecated: myzod.literals('true', 'false').optional(),
        hideInputTypes: myzod.literals('true', 'false').optional(),
        hideEnumTypes: myzod.literals('true', 'false').optional(),
        hideLeafNodes: myzod.literals('true', 'false').optional(),
        highlight: myzod
          .union([
            highlightLiterals,
            myzod.string({ pattern: /(tag|directive)-.*/ }),
          ])
          .optional(),
        showDetails: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse(params);
    return {
      ...(highlight
        ? {
            highlight: highlight as
              | ReturnType<typeof highlightLiterals.parse>
              | `${'tag' | 'directive'}-${string}`,
          }
        : {}),
      ...parsedParams,
      ...(subgraphs !== undefined
        ? { subgraphs: stableCastArray(subgraphs) }
        : {}),
      ...(tags !== undefined ? { tags: stableCastArray(tags) } : {}),
    };
  },
}).extend(TimeRangeRouteFragment);
export const sdlRouteConfig = schemaRouteConfig.extend(baseSdlRouteConfig);
export const visualizationRouteConfig = schemaRouteConfig.extend(
  baseVisualizationRouteConfig,
);

export const legacyDocumentationSchemaRouteConfig = schemaRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: `/types/:category?/:graphqlTypeName?/:linkedGraphqlEntity?`,
    parseMatchParams: (params) =>
      myzod
        .object({
          category: referenceCategorySlugSchema.optional(),
          graphqlTypeName: myzod.string().optional(),
          linkedGraphqlEntity: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const preHashLinkSchemaRouteConfig = schemaRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: `/reference/:category/:graphqlTypeName/:linkedGraphqlEntity`,
    parseMatchParams: (params) =>
      myzod
        .object({
          category: referenceCategorySlugSchema,
          graphqlTypeName: myzod.string(),
          linkedGraphqlEntity: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

type ReferenceHashParams = {
  // this is the field name or type name in the # of the referenceRoute,
  // which anchor links to said field (in a TypeFieldList) or type (in a TypeList)
  // TypeFieldList is the page shown when /graphqlTypeName exists
  linkedGraphqlEntity?: string;
};
const baseReferenceRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: `/reference/:category?/:graphqlTypeName?`,
  parseMatchParams: (params) =>
    myzod
      .object({
        category: referenceCategorySlugSchema.optional(),
        graphqlTypeName: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseSearchParams: (params) =>
    myzod
      .object({
        query: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseHash: (hash) => {
    return hash.length ? { linkedGraphqlEntity: hash } : {};
  },
  locationHash: ({ linkedGraphqlEntity }: ReferenceHashParams) =>
    linkedGraphqlEntity ?? '',
});
export const referenceRouteConfig = schemaRouteConfig.extend(
  baseReferenceRouteConfig,
);
export const proposalReferenceRouteConfig = proposalSchemaRouteConfig.extend(
  baseReferenceRouteConfig,
);
export const proposalVisualizationRouteConfig =
  proposalSchemaRouteConfig.extend(baseVisualizationRouteConfig);

export const changelogRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/changelog',
  }),
);

export const legacyHistoryRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/history',
  }),
);

export const changelogSingleChangeRouteConfig = changelogRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/version/:version',
    parseMatchParams: (params) =>
      myzod
        .object({
          version: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

// TODO: update settings route config to have a route config for each tab
/*
type SettingsTab = 'general' | 'notifications' | 'access';
... myzod.literals<SettingsTab, [SettingsTab, ...SettingsTab[]]>
*/
export const graphSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/settings/graph/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({ tab: graphSettingsSlugSchema.optional() })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          highlightVariantState: myzod
            .object({
              variant: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    // TODO: RouteConfig internal types make this a challenge and we need to pass in explicit param types.
    owners: ({ tab }: { tab?: GraphSettingsSlug }) =>
      tab === 'api-keys' || tab === 'permissions'
        ? ['astro']
        : tab === 'checks' ||
            tab === 'checks_custom_checks' ||
            tab === 'checks_linter' ||
            tab === 'checks_proposals' ||
            tab === 'proposals'
          ? ['nebula']
          : tab === 'checks_operations'
            ? ['insights']
            : tab === 'general' ||
                tab === 'variants' ||
                tab === 'reporting' ||
                tab === undefined
              ? ['Unowned'] // TODO: needs owner
              : assertUnreachableOrReturnDefault(tab, ['Unowned']),
  }),
);
export const legacyGraphsSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition:
      '/settings/:tab(notifications|access|general|reporting|permissions|api-keys|variants)?',
    parseMatchParams(params) {
      const { tab, ...parsed } = myzod
        .object({
          tab: myzod
            .literals(
              'notifications',
              'access',
              'general',
              'reporting',
              'permissions',
              'api-keys',
              'variants',
            )
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params);
      return {
        ...parsed,
        tab:
          tab === 'notifications'
            ? ('reporting' as const)
            : tab === 'access'
              ? ('permissions' as const)
              : ('general' as const),
      };
    },
    locationPathname({ tab, ...params }) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        tab:
          tab === 'reporting'
            ? 'notifications'
            : tab === 'permissions'
              ? 'access'
              : tab === 'general'
                ? 'general'
                : assertUnreachableOrReturnDefault(tab, 'general'),
      });
    },
    owners: ({ tab }) =>
      tab === 'reporting'
        ? ['Unowned'] // TODO: needs owner
        : tab === 'general'
          ? ['Unowned'] // TODO: needs owner
          : tab === 'permissions'
            ? ['astro']
            : assertUnreachableOrReturnDefault(tab, ['Unowned']),
  }),
);
export const variantSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    definition: '/settings/variant/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({
          tab: variantSettingsSlugSchema.optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          deleteSecretModalState: myzod
            .object({
              secretName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    // TODO: RouteConfig internal types make this a challenge and we need to pass in explicit param types.
    owners: ({ tab }: { tab?: VariantSettingsSlug }) =>
      tab === 'checks' ||
      tab === 'checks_custom_checks' ||
      tab === 'checks_linter' ||
      tab === 'checks_proposals' ||
      tab === 'explorer'
        ? ['nebula']
        : tab === 'checks_operations'
          ? ['insights']
          : tab === 'contracts'
            ? ['contracts']
            : tab === 'routing'
              ? ['betelgeuse']
              : tab === 'general' || tab === undefined
                ? ['Unowned'] // TODO: needs owner
                : assertUnreachableOrReturnDefault(tab, ['Unowned']),
  }),
);

/**
 * RouteConfig for the Dedicated GraphOS settings page
 */
export const cloudSettingsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['betelgeuse'],
    definition: '/cloud/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({
          tab: cloudSettingsSlugSchema.optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          deleteSecretModalState: myzod
            .object({
              secretName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * RouteConfig for the top-level `/graphs`
 */
export const sandboxGraphRouteConfig = new RouteConfig({
  owners: ['nebula'],
  definition: '/sandbox',
  parseSearchParams: (params) =>
    myzod
      .object({
        endpoint: myzod.string().optional(),
        subscriptionEndpoint: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const sandboxExplorerRouteConfig = sandboxGraphRouteConfig.extend(
  baseExplorerRouteConfig,
);

export const sandboxSchemaRouteConfig = sandboxGraphRouteConfig.extend(
  baseSchemaRouteConfig,
);

export const sandboxSdlRouteConfig =
  sandboxSchemaRouteConfig.extend(baseSdlRouteConfig);

export const sandboxVisualizationRouteConfig = sandboxSchemaRouteConfig.extend(
  baseVisualizationRouteConfig,
);

export const sandboxReferenceRouteConfig = sandboxSchemaRouteConfig.extend(
  baseReferenceRouteConfig,
);

export const sandboxDiffRouteConfig = sandboxGraphRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/diff',
  }),
);

const EmbedExplorerDefaultRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        defaultDocument: myzod.string().optional(),
        defaultHeaders: myzod.string().optional(),
        defaultVariables: myzod.string().optional(),
        defaultCollectionEntryId: myzod.string().optional(),
        defaultCollectionId: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbedDefaultRouteFragmentType = typeof EmbedDefaultRouteFragment;
const EmbedDefaultRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        version: myzod.string().optional(),
        runTelemetry: myzod.literals('true', 'false').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbeddedSandboxRouteFragmentType =
  typeof EmbeddedSandboxRouteFragment;
const EmbeddedSandboxRouteFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        initialRequestQueryPlan: myzod.literals('true', 'false').optional(),
        initialRequestConnectorsDebugging: myzod
          .literals('true', 'false')
          .optional(),
        shouldDefaultAutoupdateSchema: myzod
          .literals('true', 'false')
          .optional(),
        endpointIsEditable: myzod.literals('true', 'false').optional(),
        sharedHeaders: myzod.string().optional(),
        hideCookieToggle: myzod.literals('true', 'false').optional(),
        defaultIncludeCookies: myzod.literals('true', 'false').optional(),
        sendOperationHeadersInIntrospection: myzod
          .literals('true', 'false')
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export type EmbeddedExplorerRouteFragmentType =
  typeof EmbeddedExplorerRouteFragment;
const EmbeddedExplorerRouteFragment = new RouteConfig({
  definition: '',
  // XXX(Chang): this parseSearchParams is being called a ridiculous number of times on each variant page (e.g. schema documentation), almost always with an argument of -- {}
  // memoizing the function as a hack to avoid performance slowdowns, but this is just covering up some rot we should excise from RouteConfigs
  // https://apollographql.atlassian.net/browse/NEBULA-3181
  parseSearchParams: _.memoize(
    (params) => {
      return myzod
        .object({
          shouldPersistState: myzod.literals('true', 'false').optional(),
          docsPanelState: myzod.literals('open', 'closed').optional(),
          showHeadersAndEnvVars: myzod.literals('true', 'false').optional(),
          theme: myzod.literals('light', 'dark').optional(),
          graphRef: myzod.string().optional(),
          shouldShowGlobalHeader: myzod.literals('true', 'false').optional(),
          parentSupportsSubscriptions: myzod
            .literals('true', 'false')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params);
    },
    (...args) => {
      return JSON.stringify(args);
    },
  ),
});

// This is the route config for root path of the embedded explorer subdomain explorer.embed.apollographql.com
export const embeddableExplorerRouteConfig = new RouteConfig({
  definition: '',
})
  .extend(EmbeddedExplorerRouteFragment)
  .extend(EmbedDefaultRouteFragment)
  .extend(EmbedExplorerDefaultRouteFragment)
  .extend(ExplorerIntialValueRouteFragment)
  .extend(OperationCollectionsRouteFragment);

// This is the route config for root path of the embedded sandbox subdomain sandbox.embed.apollographql.com
export const embeddableSandboxRouteConfig = sandboxGraphRouteConfig
  .extend(EmbedDefaultRouteFragment)
  .extend(EmbedExplorerDefaultRouteFragment)
  .extend(EmbeddedSandboxRouteFragment);

export const embeddableSandboxExplorerRouteConfig = embeddableSandboxRouteConfig
  .extend(baseExplorerRouteConfig)
  .extend(ExplorerIntialValueRouteFragment)
  .extend(OperationCollectionsRouteFragment);

// TODO(cleanup): This should be part of the config it extends
export const contractsConfigRoute = variantSettingsRouteConfig.extend(
  new RouteConfig({
    definition: '',
    parseSearchParams: (params) =>
      myzod
        .object({
          sourceVariant: myzod.string().optional(),
          overlay: myzod.literals(Config.modals.configureContractWizard),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const editContractConfigRoute = variantRouteConfig.extend(
  new RouteConfig({
    definition: '',
    parseSearchParams: (params) =>
      myzod
        .object({
          sourceVariant: myzod.string().optional(),
          contractVariant: myzod.string().optional(),
          overlay: myzod.literals(Config.modals.editContractConfigWizard),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const viewContractDetailsRoute = variantRouteConfig.extend(
  new RouteConfig({
    definition: '',
    parseSearchParams: (params) =>
      myzod
        .object({
          contractVariant: myzod.string(),
          overlay: myzod.literals(Config.modals.viewContractDetails),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * Routing Config for Subgraphs L1
 */

export const subgraphsRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['Unowned'], // TODO: needs owner
    definition: '/subgraphs',
    parseSearchParams: (params) =>
      myzod
        .object({
          overlay: myzod
            .literals(
              Config.modals.deleteSubgraph,
              Config.modals.editSubgraphRoutingUrl,
              Config.modals.addSubgraph,
              Config.modals.roverAddSubgraph,
              Config.modals.roverAddVariant,
              Config.modals.reuploadSubgraph,
            )
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseState: (params) =>
      myzod
        .object({
          editRoutingUrlModalState: myzod
            .object({
              subgraphName: myzod.string(),
              routingUrl: myzod.string(),
            })
            .optional(),
          deleteSubgraphModalState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
          highlightSubgraphState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
          reuploadSubgraphModalState: myzod
            .object({
              subgraphName: myzod.string(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

// Routing configuration for Checks L1
// /graph/:graphId/variant/:graphVariant
//     /checks    ->    redirects to /variant/recent
//         /:operationTaskId    ->    redirects to ../operationsCheck/:taskId
//         /:view(graph|variant)    ->    redirects to child /recent
//             /recent
//             /config    ->    redirects to graph settings
//             /config/:tab(operations|linter|proposals|custom_checks)    ->    redirects to graph settings
//     /operationsCheck/:taskId
//     /composition/:taskId
//     /downstream/:taskId
//     /filter/:taskId
//     /lint/:taskId
//     /custom/:taskId
//     /proposal/:taskId
//
//
// /graph/:graphId/proposal/:graphVariant
//     /checks
//     /operationsCheck/:taskId
//     /composition/:taskId
//     /downstream/:taskId
//     /filter/:taskId
//     /lint/:taskId
//     /custom/:taskId
//     /proposal/:taskId
//
//
// /sandbox/checks

// common checks fragments
const ChecksFiltersFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        branch: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        variants: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        author: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        createdBy: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
        status: myzod
          .enum(GraphQLTypes.CheckFilterInputStatusOption)
          .optional(),
        subgraph: myzod
          .union([myzod.array(myzod.string()), myzod.string()])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const ProposalCheckDetailsParamsFragment = new RouteConfig({
  definition: '',
  parseHash: (hash) => {
    const [linkedSubgraphName, ...rest] = hash.split('.');
    const restJoined = rest.join('.');
    const descriptionMarkerIndex = restJoined.lastIndexOf('~');
    const linkedCoordinateName =
      descriptionMarkerIndex === -1
        ? restJoined
        : restJoined.substring(0, descriptionMarkerIndex);
    const isDescription =
      restJoined.substring(descriptionMarkerIndex + 1) === 'description';
    return linkedSubgraphName.length
      ? {
          isDescription,
          // this is the subgraph name that contains the coordinate (for multi-subgraph checks)
          linkedSubgraphName,
          // this is the coordinate name that we should reveal in the drawer
          linkedCoordinateName,
        }
      : {};
  },
  locationHash: (params) =>
    params.linkedSubgraphName && params.linkedCoordinateName
      ? `${params.linkedSubgraphName}.${params.linkedCoordinateName}${
          params.isDescription ? '~description' : ''
        }`
      : '',
});
export const OperationsCheckDetailParamsFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({
        query: myzod.string().optional(),
        [Config.queryParameters.Overlay]: myzod
          .literals('operation-details')
          .optional(),
        page: myzod.number().coerce().optional(),
        search: myzod.string().optional(),
        statusFilter: myzod
          .array(operationStatusSchema)
          .coerce((value) => [operationStatusSchema.parse(value)])
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});
export const LintAndCustomCheckViewFragment = new RouteConfig({
  definition: '',
  parseSearchParams: (params) =>
    myzod
      .object({ resultView: myzod.literals('list', 'diff').optional() })
      .allowUnknownKeys()
      .parse(params),
});

// sandbox checks
export const sandboxCheckRouteConfig = sandboxGraphRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/checks',
    parseState: (params) =>
      myzod
        .object({
          graphRefToAutoCheck: myzod
            .object({
              graphId: myzod.string(),
              graphVariant: myzod.string(),
              subgraphName: myzod.string().optional(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

// registered checks
export const checksRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/checks/:view(graph|variant)',
    parseMatchParams: (params) =>
      myzod
        .object({
          view: myzod.literals('graph', 'variant'),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);
export const recentChecksRouteConfig = checksRouteConfig
  .extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: '/recent',
    }),
  )
  .extend(ChecksFiltersFragment);
export const legacyChecksConfigRouteConfig = checksRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/config/:tab(operations|linter|proposals|custom_checks)?',
    parseMatchParams: (params) => {
      const { tab, ...parsed } = myzod
        .object({
          tab: myzod
            .literals('operations', 'linter', 'proposals', 'custom_checks')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params);
      return {
        ...parsed,
        tab: tab ? (`checks_${tab}` as const) : ('checks' as const),
      };
    },
    locationPathname({ tab, ...params }) {
      return pathToRegExp.compile(this.definition)({
        ...params,
        tab: tab === 'checks' ? undefined : tab.replace(/^checks_/, ''),
      });
    },
  }),
);
export const legacyChecksRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/checks/:checkId?',
    parseMatchParams: (params) =>
      myzod
        .object({
          checkId: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseSearchParams: (params) =>
      myzod
        .object({
          schemaTagId: myzod.string().optional(),
          tab: myzod.literals('check', 'config', 'list').optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

// new routes
export const checkRouteConfig = variantRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/check/:checkId',
    parseMatchParams: (params) =>
      myzod
        .object({ checkId: myzod.string() })
        .allowUnknownKeys()
        .parse(params),
  }),
);

const CheckSchemasFragment = new RouteConfig({
  definition: '',
  parseHash: (hash) => (hash.length ? { linkedSdl: hash } : {}),
  locationHash: ({ linkedSdl }) => linkedSdl ?? '',
});
export const checkSchemaRouteConfig = checkRouteConfig
  .extend(new RouteConfig({ owners: ['nebula'], definition: '/schemas' }))
  .extend(CheckSchemasFragment);
export const checkDetailsRouteConfig = checkRouteConfig.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/details' }),
);

// proposals checks
export const proposalChecksRouteConfig = proposalRouteConfig.extend(
  new RouteConfig({
    owners: ['proposals'],
    definition: '/checks',
  }),
);
export const proposalChecksListRouteConfig = proposalChecksRouteConfig
  .extend(
    new RouteConfig({
      definition: '',
      parseSearchParams: (params) =>
        myzod
          .object({
            page: myzod.number().coerce().optional(),
            shouldHighlightLatest: myzod.string().optional(),
          })
          .allowUnknownKeys()
          .parse(params),
    }),
  )
  .extend(ChecksFiltersFragment);

// new routes
export const proposalCheckRouteConfig = proposalRouteConfig.extend(
  new RouteConfig({
    owners: ['nebula'],
    definition: '/check/:checkId',
    parseMatchParams: (params) =>
      myzod
        .object({ checkId: myzod.string() })
        .allowUnknownKeys()
        .parse(params),
  }),
);
export const proposalCheckSchemasRouteConfig = proposalCheckRouteConfig
  .extend(new RouteConfig({ owners: ['nebula'], definition: '/schemas' }))
  .extend(CheckSchemasFragment);
export const proposalCheckDetailsRouteConfig = proposalCheckRouteConfig.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/details' }),
);

// Task details
const operationStatusSchema = myzod.literals(
  'broken',
  'potentially-affected',
  'marked-as-safe',
  'ignored',
  'unaffected',
);
export type OperationStatus = myzod.Infer<typeof operationStatusSchema>;
const OperationsCheckDetailsFragment = new RouteConfig({
  owners: ['insights'],
  definition: '/operationsCheck/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
}).extend(OperationsCheckDetailParamsFragment);
export const legacyOperationsCheckDetailsRoute = variantRouteConfig.extend(
  OperationsCheckDetailsFragment,
);
export const legacyProposalOperationsCheckDetailsRoute =
  proposalChecksRouteConfig.extend(OperationsCheckDetailsFragment);

const BuildCheckDetailsFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/composition/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
});
export const legacyBuildCheckDetailsRoute = variantRouteConfig.extend(
  BuildCheckDetailsFragment,
);
export const legacyProposalBuildCheckDetailsRoute =
  proposalChecksRouteConfig.extend(BuildCheckDetailsFragment);

const DownstreamCheckDetailsFragment = new RouteConfig({
  owners: ['contracts'],
  definition: '/downstream/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
});
export const legacyDownstreamCheckDetailsRoute = variantRouteConfig.extend(
  DownstreamCheckDetailsFragment,
);
export const legacyProposalDownstreamCheckDetailsRoute =
  proposalChecksRouteConfig.extend(DownstreamCheckDetailsFragment);

const FilterBuildCheckDetailsFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/filter/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseState: () => ({}),
  parseHash: () => ({}),
});
export const legacyFilterBuildCheckDetailsRoute = variantRouteConfig.extend(
  FilterBuildCheckDetailsFragment,
);
export const legacyProposalFilterBuildCheckDetailsRoute =
  proposalChecksRouteConfig.extend(FilterBuildCheckDetailsFragment);

const LintCheckDetailsFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/lint/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
}).extend(LintAndCustomCheckViewFragment);
export const legacyLintCheckDetailsRoute = variantRouteConfig.extend(
  LintCheckDetailsFragment,
);
export const legacyProposalLintCheckDetailsRoute =
  proposalChecksRouteConfig.extend(LintCheckDetailsFragment);

const CustomCheckDetailsFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/custom/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
}).extend(LintAndCustomCheckViewFragment);
export const legacyCustomCheckDetailsRoute = variantRouteConfig.extend(
  CustomCheckDetailsFragment,
);
export const legacyProposalCustomCheckDetailsRoute =
  proposalChecksRouteConfig.extend(CustomCheckDetailsFragment);

const ProposalCheckDetailsFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/proposal/:taskId',
  parseMatchParams: (params) =>
    myzod
      .object({
        taskId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
}).extend(ProposalCheckDetailsParamsFragment);
export const legacyProposalCheckDetailsRoute = variantRouteConfig.extend(
  ProposalCheckDetailsFragment,
);
export const legacyProposalProposalCheckDetailsRoute =
  proposalChecksRouteConfig.extend(ProposalCheckDetailsFragment);

// New task routes
const taskTypeSchema = myzod.literals(
  'operations',
  'build',
  'downstream',
  'linter',
  'custom',
  'filter',
  'proposals',
);
export type CheckTaskType = myzod.Infer<typeof taskTypeSchema>;
const CheckTasksFragment = new RouteConfig({
  owners: ['nebula'],
  definition: `/tasks/:taskType(operations|build|downstream|linter|custom|proposals|filter)?`,
  parseMatchParams: (params) =>
    myzod
      .object({ taskType: taskTypeSchema.optional() })
      .allowUnknownKeys()
      .parse(params),
});
export const checkTasksRouteConfig =
  checkRouteConfig.extend(CheckTasksFragment);
export const proposalCheckTasksRouteConfig =
  proposalCheckRouteConfig.extend(CheckTasksFragment);

const CheckLintTaskFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/tasks/linter',
}).extend(LintAndCustomCheckViewFragment);
export const checkLintTaskRouteConfig = checkRouteConfig.extend(
  CheckLintTaskFragment,
);
export const proposalCheckLintTaskRouteConfig = proposalCheckRouteConfig.extend(
  CheckLintTaskFragment,
);

const CheckCustomTaskFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/tasks/custom',
}).extend(LintAndCustomCheckViewFragment);
export const checkCustomTaskRouteConfig = checkRouteConfig.extend(
  CheckCustomTaskFragment,
);
export const proposalCheckCustomTaskRouteConfig =
  proposalCheckRouteConfig.extend(CheckCustomTaskFragment);

const CheckOperationsTaskFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/tasks/operations',
}).extend(OperationsCheckDetailParamsFragment);
export const checkOperationsTaskRouteConfig = checkRouteConfig.extend(
  CheckOperationsTaskFragment,
);
export const proposalCheckOperationsTaskRouteConfig =
  proposalCheckRouteConfig.extend(CheckOperationsTaskFragment);

export const checkFilterBuildTaskRouteConfig = checkRouteConfig.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/tasks/filter' }),
);

const CheckBuildTaskFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/tasks/build',
});
export const checkBuildTaskRouteConfig = checkRouteConfig.extend(
  CheckBuildTaskFragment,
);
export const proposalCheckBuildTaskRouteConfig =
  proposalCheckRouteConfig.extend(CheckBuildTaskFragment);

const CheckDownstreamTaskFragment = new RouteConfig({
  owners: ['nebula'],
  definition: '/tasks/downstream',
});
export const checkDownstreamTaskRouteConfig = checkRouteConfig.extend(
  CheckDownstreamTaskFragment,
);
export const proposalCheckDownstreamTaskRouteConfig =
  proposalCheckRouteConfig.extend(CheckDownstreamTaskFragment);

export const checkProposalTaskRouteConfig = checkRouteConfig
  .extend(
    new RouteConfig({ owners: ['nebula'], definition: '/tasks/proposals' }),
  )
  .extend(ProposalCheckDetailsParamsFragment);
