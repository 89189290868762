import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  Text,
} from '@apollo/orbit';
import React, { useState } from 'react';

import { ContactRequestSentModal } from 'src/components/marketoUpgradeButton/ContactRequestSentModal';
import { MarketoUpgradeButton } from 'src/components/marketoUpgradeButton/MarketoUpgradeButton';
import { PermissionWarning } from 'src/components/permissionGuards/permissionWarning/PermissionWarning';
import { PricingLink } from 'src/components/pricingLink/PricingLink';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  isDismissible?: boolean;
}

export const UpgradeModal = ({
  isOpen,
  onClose,
  isDismissible = true,
}: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [accountId = ''] = useCurrentAccountId();

  return accountId ? (
    <PermissionWarning
      accountId={accountId}
      accountPermissions={['canSetConstrainedPlan']}
      childRenderer={(hasPermission) => (
        <>
          <Modal size="xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Unlock unlimited access to GraphOS</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Text>
                  Lift restrictions for your organization and allow your team
                  access to additional features of GraphOS.
                </Text>
                {hasPermission && (
                  <Text>
                    Submitting this request will prompt the Apollo team to reach
                    out via email with more information about upgrade options.
                  </Text>
                )}
              </ModalBody>
              <ModalFooter>
                <Button as={PricingLink} variant="secondary">
                  Review plans
                </Button>
                <ButtonGroup>
                  {isDismissible && (
                    <Button
                      variant={hasPermission ? 'secondary' : 'primary'}
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  )}
                  {hasPermission && (
                    <MarketoUpgradeButton
                      onSuccess={() => {
                        setShowSuccessModal(true);
                        onClose();
                      }}
                      accountId={accountId}
                    >
                      Submit contact request
                    </MarketoUpgradeButton>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <ContactRequestSentModal
            isOpen={showSuccessModal}
            onClose={() => {
              setShowSuccessModal(false);
            }}
          />
        </>
      )}
    />
  ) : null;
};
