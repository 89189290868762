import * as myzod from 'myzod';

import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

export const NotFoundRoute = new RouteConfig({
  owners: ['Unowned'],
  definition: '/404',
});

export const BuildRoute = new RouteConfig({
  owners: ['Unowned'],
  definition: '/build',
});

export const RootRoute = new RouteConfig({
  owners: ['Unowned'],
  definition: '/',
});

export const userEmailPreferencesRoute = new RouteConfig({
  owners: ['astro'],
  definition: '/email-preferences',
  parseSearchParams: (params) =>
    myzod
      .object({
        email: myzod.string().optional(),
        token: myzod.string().optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const legacySupportRoute = new RouteConfig({
  owners: ['technicalSupport'],
  definition: '/support',
});
