import { inspect } from 'util';

import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useEffect, useState } from 'react';

import { LDFlagDefaults } from './flags';

const unexpectedLDTypeMessage = (
  flagName: string,
  typeExpected: string,
  typeReceived: unknown,
) => {
  return `Unexpected value for LaunchDarkly flag ${flagName}. Expected ${typeExpected} but received type ${inspect(
    typeReceived,
  )}`;
};

/**
 * To use a Launch Darkly rule in Studio-UI it must first be created in Launch Darkly.
 *
 * Only rules included in LDFlagDefaults will be fetched from Launch Darkly
 * and made available through the LaunchDarklyProvider & hooks.
 *
 * LDFlagDefaults is auto generated in a circleci cron from prod flags with the
 * command npm run generate-feature-flags. When a flag is changed from launch
 * darkly it will open a PR with the change.
 *
 * You can retrieve the value of your rule by calling the useLDFlag
 * hook, and passing the name of the rule.
 *
 * const myFlagValue = useLDFlag('this-string-exactly-matches-the-rule-name-in-launch-darkly');
 */
export const useLDFlag = <Flag extends keyof typeof LDFlagDefaults>(
  flagName: Flag,
): (typeof LDFlagDefaults)[Flag] => {
  const flags = useFlags<typeof LDFlagDefaults>();

  const value = flags[flagName];
  const defaultValue = LDFlagDefaults[flagName];

  if (typeof value === typeof defaultValue) {
    return value;
  }

  if (Object.keys(flags).length > 0) {
    Sentry.captureMessage(
      unexpectedLDTypeMessage(flagName, typeof defaultValue, value),
    );
  }

  return defaultValue;
};

interface FlagLoading {
  loading: true;
}

interface FlagLoaded<Flag extends keyof typeof LDFlagDefaults> {
  loading: false;
  value: (typeof LDFlagDefaults)[Flag];
}

let cachedLDLoading = true;
export const useSettledLDFlag = <Flag extends keyof typeof LDFlagDefaults>(
  flagName: Flag,
): FlagLoading | FlagLoaded<Flag> => {
  const ldClient = useLDClient();
  const [ldIsLoading, setLdIsLoading] = useState(cachedLDLoading);
  useEffect(() => {
    if (ldClient && cachedLDLoading) {
      ldClient.waitUntilReady().then(() => {
        setLdIsLoading(false);
        cachedLDLoading = false;
      });
    }
  }, [ldClient]);

  const flagValue = useLDFlag(flagName);

  if (ldIsLoading) {
    return { loading: true };
  } else {
    return { value: flagValue, loading: false };
  }
};
