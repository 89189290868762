import React, { useMemo } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { useLDFlag } from 'src/lib/launchDarkly';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';
import {
  AbsoluteOrEmptyPath,
  RouteConfig,
} from 'src/lib/routeConfig/RouteConfig';

import {
  atlasExplorerRouteConfig,
  checkBuildTaskRouteConfig,
  checkCustomTaskRouteConfig,
  checkDetailsRouteConfig,
  checkDownstreamTaskRouteConfig,
  checkFilterBuildTaskRouteConfig,
  checkLintTaskRouteConfig,
  checkOperationsTaskRouteConfig,
  checkProposalTaskRouteConfig,
  checkRouteConfig,
  checkSchemaRouteConfig,
  checkTasksRouteConfig,
  embeddableExplorerRouteConfig,
  embeddableSandboxExplorerRouteConfig,
  explorerRouteConfig,
  fieldRouteConfig,
  legacyBuildCheckDetailsRoute,
  legacyCustomCheckDetailsRoute,
  legacyDownstreamCheckDetailsRoute,
  legacyFilterBuildCheckDetailsRoute,
  legacyLintCheckDetailsRoute,
  legacyOperationsCheckDetailsRoute,
  legacyProposalBuildCheckDetailsRoute,
  legacyProposalCheckDetailsRoute,
  legacyProposalCustomCheckDetailsRoute,
  legacyProposalDownstreamCheckDetailsRoute,
  legacyProposalFilterBuildCheckDetailsRoute,
  legacyProposalLintCheckDetailsRoute,
  legacyProposalOperationsCheckDetailsRoute,
  legacyProposalProposalCheckDetailsRoute,
  proposalCheckBuildTaskRouteConfig,
  proposalCheckCustomTaskRouteConfig,
  proposalCheckDetailsRouteConfig,
  proposalCheckDownstreamTaskRouteConfig,
  proposalCheckLintTaskRouteConfig,
  proposalCheckOperationsTaskRouteConfig,
  proposalCheckRouteConfig,
  proposalCheckSchemasRouteConfig,
  proposalCheckTasksRouteConfig,
  proposalReferenceRouteConfig,
  proposalRouteConfig,
  proposalSchemaRouteConfig,
  proposalVisualizationRouteConfig,
  referenceRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
  sandboxReferenceRouteConfig,
  sandboxSchemaRouteConfig,
  sandboxSdlRouteConfig,
  sandboxVisualizationRouteConfig,
  schemaRouteConfig,
  sdlRouteConfig,
  variantRouteConfig,
  visualizationRouteConfig,
} from '../routes';

import { useGraphRef } from './useGraphRef';

type ExplorerParams = Parameters<typeof sandboxExplorerRouteConfig.location>[0];
type SDLParams = Parameters<typeof sandboxSdlRouteConfig.location>[0];
export type VisualizationParams = Parameters<
  typeof sandboxVisualizationRouteConfig.location
>[0];
type VisualizationPatch = Parameters<
  typeof sandboxVisualizationRouteConfig.locationFrom
>[0]['patch'];
export type SDLPatch = Parameters<
  typeof sandboxSdlRouteConfig.locationFrom
>[0]['patch'];
type ReferenceParams = Parameters<
  typeof sandboxReferenceRouteConfig.location
>[0];
type SchemaParams = Parameters<typeof sandboxSchemaRouteConfig.location>[0];
export type ReferencePatch = Parameters<
  typeof sandboxReferenceRouteConfig.locationFrom
>[0]['patch'];
type FieldParams = Parameters<typeof fieldRouteConfig.location>[0];

/**
 * We have multiple versions of graph routes (public/private/sandbox) for the
 * explorer and documentation pages, and possibly more later.
 *
 * The intent here is to return the same 'version' of route links as to what
 * you're currently on. If I'm viewing a sandbox explorer, I would not want to
 * link out to a private schema page route, I would want to link to the sandbox
 * schema page.
 *
 * Each of the `locationTo{routeConfig}` is meant to replace
 * `{routeConfig}.location`, 'pathTo' replacing '.path', and 'patchLink'
 * replacing '.locationFrom'.
 *
 * We also return the route configs for the current version for usage with
 * `useRouteParams`, this is more to keep some existing type weirdness around
 * the `useRouteParams` generics in one place until that gets fixed (though we
 * might decide this is more ergonomic anyways).
 *
 * The 'no-direct-route-config-access' custom lint rule enforces usage of this
 * over specific route configs within the explorer and schema page folders.
 */
export function useInternalGraphLinking(isRelativeLink = true) {
  const isSandboxRoute =
    useRouteMatch(sandboxGraphRouteConfig.definition) !== null;
  const isAtlasRoute =
    useRouteMatch(atlasExplorerRouteConfig.definition) !== null;
  const isEmbeddableRoute =
    isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute();
  const isFieldRoute = useRouteMatch(fieldRouteConfig.definition) !== null;
  const isProposalRoute =
    useRouteMatch(proposalRouteConfig.definition) !== null;
  const isVisualizationRoute =
    useRouteMatch(visualizationRouteConfig.definition) !== null;

  const { graphVisibilityType } = useRouteParams(
    variantRouteConfig,
    catchAllRouteConfig,
  );
  const graphRef = useGraphRef();
  const location = useLocation();

  // The below `any` assertions come from not being able to define a route
  // config type with a subset of the params that matches each of the
  // possible configs. I believe this comes from the same generics being used
  // both as field types, and also as function paramater types, meaning that
  // even if config A had a subset the params of config B, it would not be
  // assignable. (Eg if referenceRouteConfig extends baseReferenceRouteConfig)
  // TODO if we figure out a way around this, we should update below to not
  // need to use any.

  const currentExplorerRouteConfig = isSandboxRoute
    ? isEmbeddableRoute
      ? embeddableSandboxExplorerRouteConfig
      : sandboxExplorerRouteConfig
    : isAtlasRoute
      ? atlasExplorerRouteConfig
      : isEmbeddableRoute && isRelativeLink
        ? embeddableExplorerRouteConfig
        : explorerRouteConfig;

  const currentSdlRouteConfig = isSandboxRoute
    ? sandboxSdlRouteConfig
    : isFieldRoute
      ? fieldRouteConfig
      : sdlRouteConfig;

  const currentReferenceRouteConfig = isProposalRoute
    ? proposalReferenceRouteConfig
    : isSandboxRoute
      ? sandboxReferenceRouteConfig
      : referenceRouteConfig;

  const currentVisualizationRouteConfig = isProposalRoute
    ? proposalVisualizationRouteConfig
    : isSandboxRoute
      ? sandboxVisualizationRouteConfig
      : visualizationRouteConfig;

  const currentSchemaRouteConfig = isProposalRoute
    ? proposalSchemaRouteConfig
    : isSandboxRoute
      ? sandboxSchemaRouteConfig
      : schemaRouteConfig;

  const currentVariantRouteConfig = isProposalRoute
    ? proposalRouteConfig
    : variantRouteConfig;

  const newChecksRoutesEnabled = useLDFlag('nebula-new-check-layouts');
  const isNewCheckRoutes = useMemo(
    () =>
      [checkRouteConfig.definition, proposalCheckRouteConfig.definition].some(
        (definition) => matchPath(location.pathname, definition),
      ),
    [location.pathname],
  );
  const isOldCheckRoutes = useMemo(
    () =>
      [
        legacyProposalDownstreamCheckDetailsRoute,
        legacyDownstreamCheckDetailsRoute,
        legacyProposalBuildCheckDetailsRoute,
        legacyBuildCheckDetailsRoute,
        legacyProposalOperationsCheckDetailsRoute,
        legacyOperationsCheckDetailsRoute,
        legacyProposalFilterBuildCheckDetailsRoute,
        legacyFilterBuildCheckDetailsRoute,
        legacyProposalLintCheckDetailsRoute,
        legacyLintCheckDetailsRoute,
        legacyProposalCustomCheckDetailsRoute,
        legacyCustomCheckDetailsRoute,
        legacyProposalProposalCheckDetailsRoute,
        legacyProposalCheckDetailsRoute,
      ].some((definition) =>
        matchPath(location.pathname, definition.definition),
      ),
    [location.pathname],
  );

  const shouldUseNewChecksRoutes =
    isNewCheckRoutes || (!isOldCheckRoutes && newChecksRoutesEnabled);

  const currentCheckTasksRoute = isProposalRoute
    ? proposalCheckTasksRouteConfig
    : checkTasksRouteConfig;
  const currentCheckDetailsRoute = isProposalRoute
    ? proposalCheckDetailsRouteConfig
    : checkDetailsRouteConfig;

  const currentCheckSchemasRoute = isProposalRoute
    ? proposalCheckSchemasRouteConfig
    : checkSchemaRouteConfig;
  const currentCheckRoute = isProposalRoute
    ? proposalCheckRouteConfig
    : checkRouteConfig;

  const currentDownstreamCheckDetailsRoute = shouldUseNewChecksRoutes
    ? isProposalRoute
      ? proposalCheckDownstreamTaskRouteConfig
      : checkDownstreamTaskRouteConfig
    : isProposalRoute
      ? legacyProposalDownstreamCheckDetailsRoute
      : legacyDownstreamCheckDetailsRoute;
  const currentBuildCheckDetailsRoute = shouldUseNewChecksRoutes
    ? isProposalRoute
      ? proposalCheckBuildTaskRouteConfig
      : checkBuildTaskRouteConfig
    : isProposalRoute
      ? legacyProposalBuildCheckDetailsRoute
      : legacyBuildCheckDetailsRoute;
  const currentOperationsCheckDetailsRoute = shouldUseNewChecksRoutes
    ? isProposalRoute
      ? proposalCheckOperationsTaskRouteConfig
      : checkOperationsTaskRouteConfig
    : isProposalRoute
      ? legacyProposalOperationsCheckDetailsRoute
      : legacyOperationsCheckDetailsRoute;
  const currentFilterBuildCheckDetailsRoute = shouldUseNewChecksRoutes
    ? checkFilterBuildTaskRouteConfig
    : isProposalRoute
      ? legacyProposalFilterBuildCheckDetailsRoute // TODO: remove this
      : legacyFilterBuildCheckDetailsRoute;
  const currentLintCheckDetailsRoute = shouldUseNewChecksRoutes
    ? isProposalRoute
      ? proposalCheckLintTaskRouteConfig
      : checkLintTaskRouteConfig
    : isProposalRoute
      ? legacyProposalLintCheckDetailsRoute
      : legacyLintCheckDetailsRoute;
  const currentCustomCheckDetailsRoute = shouldUseNewChecksRoutes
    ? isProposalRoute
      ? proposalCheckCustomTaskRouteConfig
      : checkCustomTaskRouteConfig
    : isProposalRoute
      ? legacyProposalCustomCheckDetailsRoute
      : legacyCustomCheckDetailsRoute;
  const currentProposalCheckDetailsRoute = shouldUseNewChecksRoutes
    ? checkProposalTaskRouteConfig
    : isProposalRoute
      ? legacyProposalProposalCheckDetailsRoute // TODO: remove this
      : legacyProposalCheckDetailsRoute;

  const isExplorer =
    useRouteMatch(currentExplorerRouteConfig.definition) !== null;

  return React.useMemo(
    () => ({
      locationToExplorer: (params: ExplorerParams) => {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.location(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.location(params);
        }
        if (graphRef) {
          return explorerRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      pathToExplorer: (params: ExplorerParams) => {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.path(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.path(params);
        }
        if (graphRef) {
          return explorerRouteConfig.path({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      locationToSDL: (params: SDLParams) => {
        if (isSandboxRoute) {
          return sandboxSdlRouteConfig.location(params);
        }
        if (graphRef) {
          return sdlRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      locationToVisualization: (params: VisualizationParams) => {
        if (isSandboxRoute) {
          return sandboxVisualizationRouteConfig.location(params);
        }
        if (graphRef) {
          if (isProposalRoute) {
            return proposalVisualizationRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          } else {
            return visualizationRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          }
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      sdlFlags: {
        omitUrl: isFieldRoute,
        redirectOnLineCopy: !isFieldRoute,
      },
      locationToReference: (params: ReferenceParams) => {
        if (isSandboxRoute) {
          return sandboxReferenceRouteConfig.location(params);
        } else if (graphRef) {
          if (isProposalRoute) {
            return proposalReferenceRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
              patch: {
                pullUpstreamChanges: null,
              },
            });
          } else {
            return referenceRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          }
        } else {
          throw new Error(
            `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
          );
        }
      },
      locationToSchema: (params: SchemaParams) => {
        if (isSandboxRoute) {
          return sandboxSchemaRouteConfig.location(params);
        }
        if (graphRef) {
          return schemaRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      patchLinkToSdl: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
        Definition extends AbsoluteOrEmptyPath,
      >(
        patch: SDLPatch,
        fromRouteConfig?: RouteConfig<
          MatchParams,
          SearchParams,
          State,
          Hash,
          Definition
        >,
      ) => {
        if (isSandboxRoute) {
          return sandboxSdlRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return sdlRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      patchLinkToReference: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
        Definition extends AbsoluteOrEmptyPath,
      >(
        patch: ReferencePatch,
        fromRouteConfig?: RouteConfig<
          MatchParams,
          SearchParams,
          State,
          Hash,
          Definition
        >,
      ) => {
        if (isSandboxRoute) {
          return sandboxReferenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else if (isProposalRoute) {
          return proposalReferenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return referenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      patchLinkToVisualization: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
        Definition extends AbsoluteOrEmptyPath,
      >(
        patch: VisualizationPatch,
        fromRouteConfig?: RouteConfig<
          MatchParams,
          SearchParams,
          State,
          Hash,
          Definition
        >,
      ) => {
        if (isSandboxRoute) {
          return sandboxVisualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else if (isProposalRoute) {
          return proposalVisualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return visualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      locationToFieldInsights(
        params: Pick<
          FieldParams,
          'fieldName' | 'typeName' | 'insightsTab' | 'coordinateKind'
        >,
      ) {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.location(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.location(params);
        }
        if (graphRef) {
          return fieldRouteConfig.location({
            ...graphRef,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      locationToOperationsCheckTask: ({
        checkId,
        taskId,
        ...params
      }: Parameters<
        typeof legacyProposalOperationsCheckDetailsRoute.location
      >[0] &
        Parameters<typeof legacyOperationsCheckDetailsRoute.location>[0] &
        Parameters<typeof proposalCheckOperationsTaskRouteConfig.location>[0] &
        Parameters<typeof checkOperationsTaskRouteConfig.location>[0]) => {
        if (shouldUseNewChecksRoutes) {
          if (isProposalRoute) {
            return proposalCheckOperationsTaskRouteConfig.location({
              graphVisibilityType,
              ...params,
              checkId,
            });
          } else {
            return checkOperationsTaskRouteConfig.location({
              graphVisibilityType,
              ...params,
              checkId,
            });
          }
        } else if (isProposalRoute) {
          return legacyProposalOperationsCheckDetailsRoute.location({
            graphVisibilityType,
            ...params,
            taskId,
          });
        } else {
          return legacyOperationsCheckDetailsRoute.location({
            graphVisibilityType,
            ...params,
            taskId,
          });
        }
      },
      currentExplorerRouteConfig,
      currentSdlRouteConfig,
      currentReferenceRouteConfig,
      currentSchemaRouteConfig,
      currentVariantRouteConfig,
      currentVisualizationRouteConfig,
      currentBuildCheckDetailsRoute,
      currentCheckTasksRoute,
      currentCheckDetailsRoute,
      currentCheckSchemasRoute,
      currentDownstreamCheckDetailsRoute,
      currentFilterBuildCheckDetailsRoute,
      currentLintCheckDetailsRoute,
      currentOperationsCheckDetailsRoute,
      currentCustomCheckDetailsRoute,
      currentProposalCheckDetailsRoute,
      currentCheckRoute,
      isSandboxRoute,
      isExplorer,
      isProposalRoute,
      isVisualizationRoute,
    }),
    [
      isFieldRoute,
      currentExplorerRouteConfig,
      currentSdlRouteConfig,
      currentReferenceRouteConfig,
      currentSchemaRouteConfig,
      currentVariantRouteConfig,
      currentVisualizationRouteConfig,
      currentBuildCheckDetailsRoute,
      currentCheckTasksRoute,
      currentCheckDetailsRoute,
      currentCheckSchemasRoute,
      currentDownstreamCheckDetailsRoute,
      currentFilterBuildCheckDetailsRoute,
      currentLintCheckDetailsRoute,
      currentOperationsCheckDetailsRoute,
      currentCustomCheckDetailsRoute,
      currentProposalCheckDetailsRoute,
      currentCheckRoute,
      isSandboxRoute,
      isExplorer,
      isProposalRoute,
      isVisualizationRoute,
      isEmbeddableRoute,
      isRelativeLink,
      graphRef,
      location,
      graphVisibilityType,
      shouldUseNewChecksRoutes,
    ],
  );
}
