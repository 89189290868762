import React from 'react';

import { LazyUrlModal } from 'src/components/common/LazyUrlModal';
import { HigherLimitRequest } from 'src/components/higherLimitRequest/HigherLimitRequest';
import { SupportRequest } from 'src/components/support-request/SupportRequest';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { UpgradeModal } from '../account/upgradeModal/UpgradeModal';
import { NewOrganizationModal } from '../onboarding/components';
// NOTE: these modals are only rendered for AppLayout, which doesn't
// include the embeddable explorer.
// If you want to add a modal that those components need, it needs to go in `RoutedApp`
export const AppModals = () => {
  const accountId = useCurrentAccountId()[0] || '';
  const currentPlanResults = useCurrentPlan({
    accountId,
  });
  const planTier = currentPlanResults?.currentPlan?.tier;

  return (
    <>
      <LazyUrlModal name={Config.modals.newOrganization}>
        {({ closeModal, isOpen }) => (
          <NewOrganizationModal onClose={closeModal} isOpen={isOpen} />
        )}
      </LazyUrlModal>
      <LazyUrlModal name={Config.modals.supportRequest}>
        {({ isOpen, closeModal }) => (
          <SupportRequest isOpen={isOpen} closeModal={closeModal} />
        )}
      </LazyUrlModal>
      <LazyUrlModal name={Config.modals.requestHigherLimit}>
        {({ closeModal, isOpen }) => (
          <HigherLimitRequest onClose={closeModal} isOpen={isOpen} />
        )}
      </LazyUrlModal>

      {planTier !== GraphQLTypes.BillingPlanTier.ENTERPRISE && (
        <LazyUrlModal name={Config.modals.upgrade}>
          {({ closeModal, isOpen }) => (
            <UpgradeModal onClose={closeModal} isOpen={isOpen} />
          )}
        </LazyUrlModal>
      )}
    </>
  );
};
