import React from 'react';
import { Route } from 'react-router-dom-v5-compat';

import { graphRouteConfig } from 'src/app/graph/routes';

import { RootRoute } from '../routes';

import { RedirectFromGraphPathToVariantPath } from './RedirectFromGraphPathToVariantPath';
import { RootRedirect } from './RootRedirect';

export function loggedInRedirects() {
  return (
    <>
      <Route path={RootRoute.definition} Component={RootRedirect} />
      {graphRouteConfig.patchV5DefinitionsForV6(false).map((path) => (
        <Route
          key={path}
          path={path}
          // this is redirecting to the variantRouteConfig right now, but it's basically
          // a placeholder for our new "Graph Level IA" space
          Component={RedirectFromGraphPathToVariantPath}
        />
      ))}
    </>
  );
}
